import React, { Fragment } from 'react'
import styled from 'styled-components'
import {FacebookIcon, FacebookShareButton, FacebookShareCount} from "react-share";

const FBIcon = styled(FacebookIcon)`
`

const FBWrapper = styled.span`
  display: inline-block;
  background-color: #3b5998;
  height: ${props => props.size || 32}px;
  border: 1px solid green;
  border-radius: ${props => props.size}px;
  
  circle { fill: transparent }
 
`

const FBCount = styled(FacebookShareCount)`
    color: #fff;
`

class ShareWidgets extends React.Component {
  render() {
    const {url, excerpt, title} = this.props;
    const size = 32;

    return (
      <FBWrapper size={size}>
        <FacebookShareButton
          url={url}
          quote={title}
          size={size}
        >
          <FBIcon size={size} round bgColor="rgba(255,255,255,0)" />
          <FBCount url={url}>
            {count => count}
          </FBCount>
        </FacebookShareButton>
      </FBWrapper>
    )

  }

}

export default ShareWidgets
