import React from 'react'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/use-site-config'
import useSiteImages from '../hooks/use-site-images'
import { colors } from '../tokens'

const HeroContainer = styled.div`
  position: relative;
  display: table;
  width: 100%;
  height: 200px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width:768px) {
    width: 770px;
  }
`

const TitleContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
`

//const oldShadow = '1px 1px 4px rgba(34, 34, 34, 0.85);';
// Slightly deeper shadow for the main hero title
const heroShadow = '1px 1px 7px rgba(34,34,34,0.9)';
const heroSubShadow = '1px 1px 3px rgba(20,20,20,0.9)';

const HeroTitle = styled.h1`
  font-weight: 700;
  font-size: 3rem;
  margin: 10px 50px;
  color: ${colors.white};
  text-shadow: ${heroShadow};
`

const HeroSubTitle = styled.h2`
  margin: 10px 50px;
  color: ${colors.white};
  text-shadow: ${heroSubShadow};
  @media screen and (max-width: 767px) {
    display: none;
  }
`

const Hero = props => {
  const { siteCover } = useSiteMetadata()
  const { fluid } = useSiteImages(siteCover)
  const heroImg = props.heroImg || fluid.src

  return (
    <HeroContainer style={{ backgroundImage: `url("${heroImg}")` }}>
      <TitleContainer>
        {props.title && <HeroTitle>{props.title}</HeroTitle>}
        {props.subTitle && <HeroSubTitle>{props.subTitle}</HeroSubTitle>}
        {props.children}
      </TitleContainer>
    </HeroContainer>
  )
}

export default Hero
