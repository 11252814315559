import React, { Fragment } from 'react'
import styled from 'styled-components'
import TagList from './TagList'
import Translations from './Translations'
import Time from './Time'
import { colors } from '../tokens'
import { Bull } from './Commons'
import ShareWidgets from './ShareWidgets'
import useSiteMetadata from "../hooks/use-site-config";

const Header = styled.header`
  margin-bottom: 2rem;
  color: ${colors.textLight};
  font-size: 0.9rem;
`

const Crumb = styled.a`
  font-size: 0.9rem;
  margin-left: 0.5rem;
  color: ${colors.textLight};
`

class ContentIntro extends React.Component {

  render() {
    const { date, tags, translations, post } = this.props

    const excerpt = post.excerpt;
    const title = post.frontmatter.title;
    const slug = post.frontmatter.slug;
    const url = `https://kingofthering.fi/${slug}/`;

    const lang = post.frontmatter.language;

    let breadcrumbs = [];
    if (slug !== '/' && slug !== '/en' && slug != '/en/') {
      if(lang === 'fi') {
        breadcrumbs.push({
          a: '/',
          text: 'Etusivu'
        });
      } else {
        breadcrumbs.push({
          a: '/en/',
          text: 'Front page',
        });
      }
      breadcrumbs.push({
        a: '/' + post.frontmatter.slug,
        text: post.frontmatter.title,
      })
    }
    // Map to elements
    if(breadcrumbs) {
      breadcrumbs = breadcrumbs.map(bc => (
        <Crumb href={bc.a} key={bc.a}>{bc.text} <small>&gt;</small></Crumb>
      ))
    }

    const shareFlag = false;

    return (
      <Header>
        {}
        {date && <Time date={date} language={lang} />}
        {breadcrumbs && breadcrumbs}
        {date && Array.isArray(tags) && tags.length > 0 && <Bull />}
        {Array.isArray(tags) && tags.length > 0 && (
          <Fragment>
            <TagList tags={tags} />
          </Fragment>
        )}
        {shareFlag && <ShareWidgets url={url} title={title} excerpt={excerpt} />}
        {translations && <Translations translations={translations} />}
      </Header>
    )
  }
}

export default ContentIntro
