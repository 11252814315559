import React from 'react'
import Helmet from 'react-helmet'
import { withPrefix } from 'gatsby'
import useSiteMetadata from '../hooks/use-site-config'
import styled from 'styled-components'
import useSiteImages from "../hooks/use-site-images";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";


const SidebarContainer = styled.aside`
    position: absolute;
    right: -12rem;
    width: 10rem;
    display: flex;
    flex-direction: column;
    top: 0;
    
    @media only screen and (max-width : 992px) {
      display: none;
      width: 0;
    }
`;

const SidebarElement = styled.div`
    margin-bottom: 2rem;
`;

const KotrTitle = styled.div`
    margin-bottom: .6rem;
    font-weight: bold;
    font-size: 1.2rem;
`;

const FlexRow = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: space-around;
`;

const FBIcon = styled(FaFacebookSquare)`
  width: 3em;
  height: 3em;
`

const InstaIcon = styled(FaInstagram)`
  width: 3em;
  height: 3em;
`

const Sidebar = props => {
    const kotr = useSiteImages('kotr-on-facebook-black.png').fluid.src;
    const jomhod = useSiteImages('jomhod-belts-300x171.png').fluid.src;

    return (
        <SidebarContainer>
            <SidebarElement>
              <KotrTitle style={{textAlign: 'center'}}>
                King of the Ring <small>somessa:</small>
              </KotrTitle>
              <FlexRow>
                <a href="https://www.facebook.com/KingOfTheRingThaiboxing"
                   target="_blank">
                   <FBIcon />
                   {/*
                    <img src={kotr}
                         alt="Löydä King of the Ring thainyrkkeilyseura Facebookista"
                         title="Löydä King of the Ring thainyrkkeilyseura Facebookista" />
                   */}
                </a>
                <a href="https://www.instagram.com/kingoftheringhki/" target="_blank">
                  <InstaIcon />
                </a>
              </FlexRow>
            </SidebarElement>
            <SidebarElement>
                <a href="http://www.jomhod.com" target="_blank" title="Jomhod.com">
                    <img src={jomhod}
                         alt="Jomhod.com"
                        />
                        {/* style="width: 150px;" */}
                </a>
            </SidebarElement>
        </SidebarContainer>
    );
};

export default Sidebar;
